<template>
  <div style="padding-bottom: 12px">
    <div class="right">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table
      bordered
      :data-source="Array.isArray(detail.changeList) ? detail.changeList : []"
      :pagination="false"
    >
      <a-table-column title="异动类型">
        <template slot-scope="text">
          <a-select
            v-model="text.type"
            placeholder="请选择"
            size="small"
            style="width: 120px"
          >
            <a-select-option
              v-for="item in changeType"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>
      <a-table-column title="拟调出部门">
        <template slot-scope="text">
          <a-select
            show-search
            :filter-option="$selectFilterOption"
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.deptFrom"
          >
            <a-select-option
              v-for="item in organizationList"
              :key="item.id"
              :value="item.id"
              >{{ item.uniqueName }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>

      <a-table-column title="拟调入部门">
        <template slot-scope="text">
          <a-select
            show-search
            :filter-option="$selectFilterOption"
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.deptTo"
          >
            <a-select-option
              v-for="item in organizationList"
              :key="item.id"
              :value="item.id"
              >{{ item.uniqueName }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>

      <!-- <a-table-column title="拟调整岗位">
        <template slot-scope="text">
          <a-input
            v-model="text.post"
            placeholder="请输入"
            size="small"
            style="width: 120px"
          />
        </template>
      </a-table-column>
      <a-table-column title="拟调整职级">
        <template slot-scope="text">
          <a-input
            v-model="text.rank"
            placeholder="请输入"
            size="small"
            style="width: 120px"
          />
        </template>
      </a-table-column>

      <a-table-column title="调整生效日期">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.effectiveTime"
          />
        </template>
      </a-table-column> -->
      <a-table-column title="异动日期">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.changeTime"
          />
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    changeType: {
      type: Array,
      default: () => [],
    },
    organizationList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    add() {
      if (Array.isArray(this.detail.changeList)) {
        this.detail.changeList.push({});
      } else {
        this.detail.changeList = [{}];
      }
    },

    remove(index) {
      this.detail.changeList.splice(index, 1);
    },
  },
};
</script>