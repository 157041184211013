<template>
  <div style="padding-bottom: 12px">
    <div class="right">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.relativeList) ? detail.relativeList : []
      "
      :pagination="false"
    >
      <a-table-column title="关系人姓名">
        <template slot-scope="text">
          <a-input
            v-model="text.name"
            placeholder="请输入"
            size="small"
            style="width: 120px"
          />
        </template>
      </a-table-column>
      <a-table-column title="人员类型">
        <template slot-scope="text">
          <a-select
            v-model="text.typePerson"
            placeholder="请选择"
            size="small"
            style="width: 120px"
          >
            <a-select-option
              v-for="item in personType"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>
      <a-table-column title="关系类型">
        <template slot-scope="text">
          <a-select
            v-model="text.type"
            placeholder="请选择"
            size="small"
            style="width: 120px"
          >
            <a-select-option
              v-for="item in relationshipType"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>

      <a-table-column title="手机号">
        <template slot-scope="text">
          <a-input
            style="width: 120px"
            size="small"
            placeholder="请输入"
            v-model="text.mobile"
          />
        </template>
      </a-table-column>
      <a-table-column title="工作单位">
        <template slot-scope="text">
          <a-input
            style="width: 120px"
            size="small"
            placeholder="请输入"
            v-model="text.workUnit"
          />
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    personType: {
      type: Array,
      default: () => [],
    },
    relationshipType: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    add() {
      if (Array.isArray(this.detail.relativeList)) {
        this.detail.relativeList.push({});
      } else {
        this.detail.relativeList = [{}];
      }
    },
    remove(index) {
      this.detail.relativeList.splice(index, 1);
    },
  },
};
</script>