<template>
  <div>
    <div class="left header">
      <div class="title">工作经历</div>
      <div class="extra">
        <a-button type="primary" size="small" @click="addWork">添加</a-button>
      </div>
    </div>

    <a-table
      bordered
      :data-source="Array.isArray(detail.hisWorkList) ? detail.hisWorkList : []"
      :pagination="false"
    >
      <a-table-column title="公司">
        <template slot-scope="text">
          <a-input
            style="width: 120px"
            size="small"
            placeholder="请输入"
            v-model="text.company"
          />
        </template>
      </a-table-column>
      <a-table-column title="部门">
        <template slot-scope="text">
          <a-input
            style="width: 120px"
            size="small"
            placeholder="请输入"
            v-model="text.dept"
          />
        </template>
      </a-table-column>

      <a-table-column title="职务">
        <template slot-scope="text">
          <a-input
            style="width: 120px"
            size="small"
            placeholder="请输入"
            v-model="text.position"
          />
        </template>
      </a-table-column>

      <a-table-column title="开始日期" align="center">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.timeFrom"
          />
        </template>
      </a-table-column>
      <a-table-column title="结束日期" align="center">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.timeTo"
          />
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="removeWork(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>

    <div style="padding-top: 12px"></div>

    <div class="left header">
      <div class="title">项目经历</div>
      <div class="extra">
        <a-button type="primary" size="small" @click="addProject"
          >添加</a-button
        >
      </div>
    </div>

    <a-table
      bordered
      :data-source="
        Array.isArray(detail.hisProjectList) ? detail.hisProjectList : []
      "
      :pagination="false"
    >
      <a-table-column title="项目名称">
        <template slot-scope="text">
          <a-input
            style="width: 120px"
            size="small"
            placeholder="请输入"
            v-model="text.name"
          />
        </template>
      </a-table-column>

      <a-table-column title="项目金额(元)">
        <template slot-scope="text">
          <a-input-number
            :formatter="$inputNumberFormat"
            style="width: 120px"
            size="small"
            :min="0"
            placeholder="请输入"
            v-model="text.amount"
          />
        </template>
      </a-table-column>

      <a-table-column title="项目级别">
        <template slot-scope="text">
          <a-select
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.level"
          >
            <a-select-option
              v-for="item in typeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </template>
      </a-table-column>

      <a-table-column title="担任角色">
        <template slot-scope="text">
          <a-select
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.role"
          >
            <a-select-option
              v-for="item in roleList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </template>
      </a-table-column>

      <a-table-column title="开始日期" align="center">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.timeFrom"
          />
        </template>
      </a-table-column>

      <a-table-column title="结束日期" align="center">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.timeTo"
          />
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="removeProject(index)"
            >删除</a
          >
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("project-type");
    },

    roleList() {
      return this.findDataDict("project-role");
    },
  },

  methods: {
    addWork() {
      if (Array.isArray(this.detail.hisWorkList)) {
        this.detail.hisWorkList.push({});
      } else {
        this.detail.hisWorkList = [{}];
      }
    },
    addProject() {
      if (Array.isArray(this.detail.hisProjectList)) {
        this.detail.hisProjectList.push({});
      } else {
        this.detail.hisProjectList = [{}];
      }
    },

    removeWork(index) {
      this.detail.hisWorkList.splice(index, 1);
    },
    removeProject(index) {
      this.detail.hisProjectList.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding-bottom: 12px;

  .title {
    font-size: 14px;
    font-weight: bold;
  }

  .extra {
    margin-left: auto;
  }
}
</style>