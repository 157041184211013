<template>
  <div style="padding-bottom: 12px">
    <div class="right">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table
      bordered
      :scroll="{x: 'max-content'}"
      :data-source="Array.isArray(detail.trainList) ? detail.trainList : []"
      :pagination="false"
    >
      <a-table-column title="培训名称">
        <template slot-scope="text">
          <a-input
            v-model="text.name"
            placeholder="请输入"
            size="small"
            style="width: 180px"
          />
        </template>
      </a-table-column>
      <a-table-column title="培训日期">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.time"
          />
        </template>
      </a-table-column>

      

      <a-table-column title="培训地点">
        <template slot-scope="text">
          <a-input
            v-model="text.address"
            placeholder="请输入"
            size="small"
            style="width: 180px"
          />
        </template>
      </a-table-column>

      <a-table-column title="服务期起始日期">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.startTime"
          />
        </template>
      </a-table-column>

      <a-table-column title="服务期结束日期">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.endTime"
          />
        </template>
      </a-table-column>
      
      <a-table-column title="培训机构">
        <template slot-scope="text">
          <a-input
            style="width: 180px"
            size="small"
            placeholder="请输入"
            v-model="text.mechanism"
          />
        </template>
      </a-table-column>

      <a-table-column title="培训讲师">
        <template slot-scope="text">
          <a-input
            style="width: 180px"
            size="small"
            placeholder="请输入"
            v-model="text.teacher"
          />
        </template>
      </a-table-column>


      <a-table-column title="培训结果">
        <template slot-scope="text">
          <a-input
            style="width: 180px"
            size="small"
            placeholder="请输入"
            v-model="text.result"
          />
        </template>
      </a-table-column>
      
      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    add() {
      if (Array.isArray(this.detail.trainList)) {
        this.detail.trainList.push({});
      } else {
        this.detail.trainList = [{}];
      }
    },

    remove(index) {
      this.detail.trainList.splice(index, 1);
    },
  },
};
</script>