<template>
  <div class="base-info">
    <div class="tool">
      <a-button-group size="small">
        <a-button @click="allClose">全部收起</a-button>
        <a-button type="primary" @click="foldList = []"> 全部展开 </a-button>
      </a-button-group>
    </div>

    <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }" :colon="false">
      <a-row>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="手机号码">
            <a-input v-decorator="[
              'mobile',
              {
                initialValue: detail.mobile,

                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="邮箱">
            <a-input v-decorator="[
              'email',
              {
                initialValue: detail.email,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="员工类别">
            <a-select v-decorator="[
              'type',
              {
                initialValue: detail.type,

                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]">
              <a-select-option v-for="item in employeeType" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="员工状态">
            <a-select v-decorator="[
              'status',
              {
                initialValue: detail.status,

                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]">
              <a-select-option v-for="item in employeeStatus" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <CardHeader :icon="icon1" title="基本信息" :fold="foldList.indexOf(0) > -1" @fold="onFold(0)" />

      <a-row v-show="foldList.indexOf(0) === -1">
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="姓名">
            <a-input v-decorator="[
              'name',
              {
                initialValue: detail.name,
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>
        

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="性别">
            <a-radio-group v-decorator="[
              'sex',
              {
                initialValue: detail.sex,

                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]">
              <a-radio v-for="item in sex" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="出生日期">
            <a-date-picker style="width: 100%" placeholder="" v-decorator="[
              'birthday',
              {
                initialValue: detail.birthday,
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>

        <!-- <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="年龄">
            <a-button block style="text-align: left">{{
              getDateLength("birthday")
            }}</a-button>
          </a-form-item>
        </a-col> -->

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="国籍">
            <a-input v-decorator="[
              'nationality',
              {
                initialValue: detail.nationality,
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="籍贯">
            <a-input v-decorator="[
              'nativePlace',
              {
                initialValue: detail.nativePlace,
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="民族">
            <a-input v-decorator="[
              'ethnicGroup',
              {
                initialValue: detail.ethnicGroup,
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="政治面貌">
            <a-select v-decorator="[
              'political',
              {
                initialValue: detail.political,
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]">
              <a-select-option v-for="item in politicalOutlookList" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="入党(团)日期">
            <a-date-picker format="YYYY年M月" :disabled="true" style="width: 100%" placeholder="" v-decorator="[
              'politicalDate',
              {
                initialValue: detail.politicalDate,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="党员标识">
            <a-select :allowClear="true" :disabled="true" v-decorator="[
              'partyMember',
              {
                initialValue: detail.partyMember,
              },
            ]">
              <a-select-option v-for="item in partyList" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <!-- <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="血型">
            <a-select :allowClear="true" v-decorator="[
              'bloodType',
              {
                initialValue: detail.bloodType,
              },
            ]">
              <a-select-option v-for="item in bloodTypeList" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col> -->
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="最高学历">
            <a-select v-decorator="[
              'degree',
              {
                initialValue: detail.degree,
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]">
              <a-select-option v-for="item in degree" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="身高(cm)">
            <a-input v-decorator="[
              'height',
              {
                initialValue: detail.height,
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="体重(kg)">
            <a-input v-decorator="[
              'weight',
              {
                initialValue: detail.weight,
              },
            ]" />
          </a-form-item>
        </a-col>
        <!-- <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="肤色">
            <a-input v-decorator="[
              'skinColor',
              {
                initialValue: detail.skinColor,
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="发色">
            <a-input v-decorator="[
              'hairColor',
              {
                initialValue: detail.hairColor,
              },
            ]" />
          </a-form-item>
        </a-col> -->

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="兴趣爱好">
            <a-input v-decorator="[
              'interests',
              {
                initialValue: detail.interests,
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="特长">
            <a-input v-decorator="[
              'expertises',
              {
                initialValue: detail.expertises,
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="婚姻状况">
            <a-select v-decorator="[
              'marryType',
              {
                initialValue: detail.marryType,
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]">
              <a-select-option v-for="item in marrigeStatus" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="子女状况">
            <a-select v-decorator="[
              'childrenType',
              {
                initialValue: detail.childrenType,
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]">
              <a-select-option v-for="item in childStatus" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="入职日期">
            <a-date-picker style="width: 100%" placeholder="" :disabled="!isHr" v-decorator="[
              'entryDate',
              {
                initialValue: detail.entryDate,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="司龄">
            <a-button block style="text-align: left">
              {{ getDateLength("entryDate") }}
            </a-button>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="参加工作日期">
            <a-date-picker style="width: 100%" placeholder="" :disabled="!isHr" v-decorator="[
              'workStartTime',
              {
                initialValue: detail.workStartTime,
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="社会工龄">
            <a-button block style="text-align: left">
              {{ getDateLength("workStartTime") }}
            </a-button>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="主要社会职务">
            <a-input v-decorator="[
              'socialPost',
              {
                initialValue: detail.socialPost,
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>

        
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="职称">
            <a-select @change="qualificationsSelection" v-decorator="[
              'professionalTitle',
              {
                initialValue: detail.professionalTitle,
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]">
              <a-select-option v-for="item in professionalTitle" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="职称时间">
            <a-date-picker style="width: 100%" placeholder="" v-decorator="[
              'professionalDate',
              {
                initialValue: detail.professionalDate,
                rules: [
                  {
                    required: requireProfessionalTime,
                    message: '请选择！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="注册执业资格">
            <a-select mode="multiple" v-decorator="[
              'registeredProfessionalQualifications',
              {
                initialValue: detail.registeredProfessionalQualifications ? detail.registeredProfessionalQualifications.split(',') :[],
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]">
              <a-select-option v-for="item in registeredProfessionalQualifications" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <!-- <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="荣誉">
            <a-select :allowClear="true" v-decorator="[
              'honorFlag',
              {
                initialValue: detail.honorFlag,
              },
            ]">
              <a-select-option v-for="item in logo" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="户口类型">
            <a-select :allowClear="true" v-decorator="[
              'registerResidenceType',
              {
                initialValue: detail.registerResidenceType,
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]">
              <a-select-option v-for="item in registerResidenceType" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col> -->

        <!-- <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="户口所在地">
            <el-cascader
                style="width: 100%"
                filterable
                clearable
                :options="options"
                v-model="selectedOptions"
            ></el-cascader>
          </a-form-item>
        </a-col> -->

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="紧急联系人">
            <a-input v-decorator="[
              'emergencyContact',
              {
                initialValue: detail.emergencyContact,
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="紧急联系人电话">
            <a-input v-decorator="[
              'emergencyContactMobile',
              {
                initialValue: detail.emergencyContactMobile,
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="残疾标识">
            <a-select :allowClear="true" v-decorator="[
              'deformityFlag',
              {
                initialValue: detail.deformityFlag,
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]">
              <a-select-option v-for="item in disabilitySign" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <CardHeader :icon="icon3" title="岗位信息" :fold="foldList.indexOf(2) > -1" @fold="onFold(2)" />
      <a-row v-show="foldList.indexOf(2) === -1">
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="归属部门">
            <a-select show-search :disabled="!isHr" :filter-option="$selectFilterOption" v-decorator="[
              'belongDept',
              {
                initialValue: detail.position
                  ? detail.position.belongDept
                  : undefined,
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]" @change="handleDeptChange">
              <a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{ item.uniqueName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="所">
            <a-select :allowClear="true" v-decorator="[
              'instituteId',
              {
                initialValue: detail.instituteId
                  ? detail.instituteId
                  : undefined,
              },
            ]" @change="onPlaceChange">
              <a-select-option v-for="item in placeList" :key="item.id" :value="item.id">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="组">
            <a-select :allowClear="true" v-decorator="[
              'groupId',
              {
                initialValue: detail.groupId ? detail.groupId : undefined,
              },
            ]">
              <a-select-option v-for="item in groupList" :key="item.id" :value="item.id">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="岗位">
            <a-select :allowClear="true" v-decorator="[
              'position',
              {
                initialValue: detail.position
                  ? detail.position.position
                  : undefined,
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],  
              },
            ]">
              <a-select-option v-for="item in positionList" :key="item.value" :value="item.value">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="从事专业">
            <a-input v-decorator="[
              'professionalize',
              {
                initialValue: detail.position
                  ? detail.position.professionalize
                  : undefined,
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],   
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="直接上级姓名">
            <a-input :disabled="!isHr" v-decorator="[
              'directSupervisor',
              {
                initialValue: detail.position
                  ? detail.position.directSupervisor
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="岗位序列">
            <a-input :disabled="!isHr" v-decorator="[
              'postSeries',
              {
                initialValue: detail.position
                  ? detail.position.postSeries
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>
        
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="职级">
            <a-input :disabled="!isHr" v-decorator="[
              'rank',
              {
                initialValue: detail.position
                  ? detail.position.rank
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="职等">
            <a-input :disabled="!isHr" v-decorator="[
              'grade',
              {
                initialValue: detail.position
                  ? detail.position.grade
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        

        <!-- <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="当前职称开始日期">
            <a-date-picker style="width: 100%" placeholder="" v-decorator="[
              'rankStartTime',
              {
                initialValue:
                  detail.position && detail.position.rankStartTime
                    ? detail.position.rankStartTime
                    : undefined,
              },
            ]" />
          </a-form-item>
        </a-col> -->
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="任职开始日期">
            <a-date-picker :disabled="!isHr" style="width: 100%" placeholder="" v-decorator="[
              'postStartTime',
              {
                initialValue:
                  detail.position && detail.position.postStartTime
                    ? detail.position.postStartTime
                    : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="任职结束日期">
            <a-date-picker :disabled="!isHr" style="width: 100%" placeholder="" v-decorator="[
              'postEndTime',
              {
                initialValue:
                  detail.position && detail.position.postEndTime
                    ? detail.position.postEndTime
                    : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <!-- <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="合同开始日期">
            <a-date-picker style="width: 100%" placeholder="" v-decorator="[
              'contractStartDate',
              {
                initialValue: detail.contractStartDate
                  ? detail.contractStartDate
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="合同结束日期">
            <a-date-picker style="width: 100%" placeholder="" v-decorator="[
              'contractEndDate',
              {
                initialValue: detail.contractEndDate
                  ? detail.contractEndDate
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col> -->
      </a-row>
      <Position v-show="foldList.indexOf(2) === -1" :detail="detail" @lineSelect="updatePosition"/>

      <CardHeader :icon="icon3" title="合同信息" :fold="foldList.indexOf(16) > -1" @fold="onFold(16)" />
      <a-row v-show="foldList.indexOf(16) === -1">
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="试用期限(月)">
            <a-input-number :disabled="!isHr" v-decorator="[
              'term',
              {
                initialValue: detail.probation ? detail.probation.term : null,
              },
            ]" style="width: 100%" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="开始日期">
            <a-date-picker :disabled="!isHr" style="width: 100%" placeholder="" v-decorator="[
              'startTime',
              {
                initialValue: detail.probation
                  ? detail.probation.startTime
                  : null,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="计划结束日期">
            <a-date-picker :disabled="!isHr" style="width: 100%" placeholder="" v-decorator="[
              'endTimeExpected',
              {
                initialValue: detail.probation
                  ? detail.probation.endTimeExpected
                  : null,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="实际结束日期">
            <a-date-picker :disabled="!isHr" style="width: 100%" placeholder="" v-decorator="[
              'endTimeActual',
              {
                initialValue: detail.probation
                  ? detail.probation.endTimeActual
                  : null,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="转正日期">
            <a-date-picker :disabled="!isHr" style="width: 100%" placeholder="" v-decorator="[
              'qualifiedTime',
              {
                initialValue: detail.probation
                  ? detail.probation.qualifiedTime
                  : null,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="考核结果" :label-col="{ span: 2 }" :wrapper-col="{ span: 21 }">
            <a-textarea :disabled="!isHr" v-decorator="[
              'result',
              {
                initialValue: detail.probation
                  ? detail.probation.result
                  : null,
              },
            ]" />
          </a-form-item>
        </a-col>
      </a-row>
      <Change v-show="foldList.indexOf(16) === -1" :detail="detail" :changeType="changeType"
        :organizationList="organizationListNew" />
      <Contract v-show="foldList.indexOf(16) === -1" :detail="detail" />

      <CardHeader :icon="icon6" title="教育经历" :fold="foldList.indexOf(5) > -1" @fold="onFold(5)" />
      <div>从高中学历以上开始填写</div>
      <Education v-show="foldList.indexOf(5) === -1" :detail="detail" :graduationWay="graduationWay"
      :education_channel="education_channel" :degree="degree" :academic_degree="academic_degree" />

      <CardHeader :icon="icon2" title="证件信息" :fold="foldList.indexOf(1) > -1" @fold="onFold(1)" />
      <Certificates v-show="foldList.indexOf(1) === -1" :detail="detail" :credentialType="credentialType" />

      <!-- <CardHeader :icon="icon4" title="联系信息" :fold="foldList.indexOf(3) > -1" @fold="onFold(3)" />

      <a-row v-show="foldList.indexOf(3) === -1">
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="办公电话">
            <a-input v-decorator="[
              'phone',
              {
                initialValue: detail.phone,
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="个人邮箱">
            <a-input v-decorator="[
              'emailIndividual',
              {
                initialValue: detail.emailIndividual,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="通讯地址">
            <a-input v-decorator="[
              'address',
              {
                initialValue: detail.address,
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="办公地址">
            <a-input v-decorator="[
              'addressWork',
              {
                initialValue: detail.addressWork,
              },
            ]" />
          </a-form-item>
        </a-col>
      </a-row> -->

      <CardHeader :icon="icon5" title="家庭及主要社会关系信息" :fold="foldList.indexOf(4) > -1" @fold="onFold(4)" />

      <Family v-show="foldList.indexOf(4) === -1" :detail="detail" :personType="personType"
        :relationshipType="relationshipType" />

      

      <CardHeader :icon="icon7" title="证书信息" :fold="foldList.indexOf(6) > -1" @fold="onFold(6)" />

      <Book v-show="foldList.indexOf(6) === -1" :detail="detail" :certificateType="certificateType" :certificateTypeB="certificateTypeB"/>
      <!-- <CardHeader :icon="icon8" title="技能信息" :fold="foldList.indexOf(7) > -1" @fold="onFold(7)" />

      <Skill v-show="foldList.indexOf(7) === -1" :detail="detail" :professionalTitle="professionalTitle" /> -->
      <!-- <Language v-show="foldList.indexOf(7) === -1" :detail="detail" /> -->

      <!-- <Padding />

      <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }" label="特殊贡献" v-show="foldList.indexOf(7) === -1">
        <a-textarea v-decorator="[
          'honorContribute',
          {
            initialValue: detail.honorContribute,
          },
        ]" />
      </a-form-item>
      <a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }" label="特殊经历" v-show="foldList.indexOf(7) === -1">
        <a-textarea v-decorator="[
          'honorExperience',
          {
            initialValue: detail.honorExperience,
          },
        ]" />
      </a-form-item> -->

      <CardHeader :icon="icon9" title="荣誉信息" :fold="foldList.indexOf(8) > -1" @fold="onFold(8)" />

      <Honor v-show="foldList.indexOf(8) === -1" :detail="detail" :honorLogo="honorLogo" :honorLevel="honorLevel"
        :externalHonorLevel="externalHonorLevel" />

      <CardHeader :icon="icon10" title="惩罚信息" :fold="foldList.indexOf(9) > -1" @fold="onFold(9)" />

      <Reward v-show="foldList.indexOf(9) === -1" :detail="detail" :rewardPenaltyType="rewardPenaltyType" />

      <CardHeader :icon="icon13" title="绩效考核" :fold="foldList.indexOf(12) > -1" @fold="onFold(12)" />

      <Performance v-show="foldList.indexOf(12) === -1" :detail="detail" />

      <CardHeader :icon="icon15" title="培训记录" :fold="foldList.indexOf(14) > -1" @fold="onFold(14)" />

      <Train v-show="foldList.indexOf(14) === -1" :detail="detail" />

      <CardHeader :icon="icon14" title="人才识别" :fold="foldList.indexOf(13) > -1" @fold="onFold(13)" />

      <a-row v-show="foldList.indexOf(13) === -1">
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="第三方评测得分">
            <a-input-number style="width: 100%" v-decorator="[
              'evaluation3rd',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.evaluation3rd
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="文化匹配度">
            <a-input-number style="width: 100%" v-decorator="[
              'culture',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.culture
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="综合能力">
            <a-input-number style="width: 100%" v-decorator="[
              'comprehensive',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.comprehensive
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="管理能力">
            <a-input-number style="width: 100%" v-decorator="[
              'abilityManage',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.abilityManage
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="技术能力">
            <a-input-number style="width: 100%" v-decorator="[
              'abilitySkill',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.abilitySkill
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="销售能力">
            <a-input-number style="width: 100%" v-decorator="[
              'abilitySale',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.abilitySale
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="沟通能力">
            <a-input-number style="width: 100%" v-decorator="[
              'abilityCommunicate',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.abilityCommunicate
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="学习能力">
            <a-input-number style="width: 100%" v-decorator="[
              'abilityLearn',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.abilityLearn
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="人才发展积分">
            <a-input-number style="width: 100%" v-decorator="[
              'scoreDevelop',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.scoreDevelop
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="360评价得分">
            <a-input-number style="width: 100%" v-decorator="[
              'score360',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.score360
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="后备人才">
            <a-select :allowClear="true" style="width: 100%" v-decorator="[
              'reserveTalentFlag',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.reserveTalentFlag
                  : undefined,
              },
            ]">
              <a-select-option v-for="item in reserveTalentLogo" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="实习期">
            <a-input v-decorator="[
              'internship',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.internship
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="360评价结果" :label-col="{ span: 2 }" :wrapper-col="{ span: 21 }">
            <a-textarea v-decorator="[
              'result360',
              {
                initialValue: detail.talentIdentify
                  ? detail.talentIdentify.result360
                  : undefined,
              },
            ]" />
          </a-form-item>
        </a-col>
      </a-row>

      <Personel v-show="foldList.indexOf(13) === -1" :detail="detail" />



      <CardHeader :icon="icon1" title="工作项目" :fold="foldList.indexOf(15) > -1" @fold="onFold(15)" />
      <Projects v-show="foldList.indexOf(15) === -1" :detail="detail" />

      <div class="center">
        <a-space>
          <a-button :loading="saveLoading" htmlType="submit" type="primary">保存</a-button>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import moment from 'moment';
import { provinceAndCityData } from 'element-china-area-data'
import organization from "@/mixins/organization";
import CardHeader from "./card-header.vue";
import Certificates from "./certificates.vue";
import Family from "./family.vue";
import Education from "./education.vue";
import Book from "./book.vue";
// import Skill from "./skill.vue";
// import Language from "./language.vue";
import Honor from "./honor.vue";
import Reward from "./reward.vue";
import Change from "./change.vue";
import Performance from "./performance.vue";
import Personel from "./personel.vue";
import Train from "./train.vue";
import Projects from "./projects.vue";

import { edit } from "@/api/human-resources/employee";
import { mapGetters } from "vuex";

import { fetchDetailTree } from "@/api/setting/organization";
import Contract from "./contract.vue";
import Position from "./position.vue";
import request from "@/api/request";
function fetchOrganization() {
    return request({
        url: "/user-service/org/all2",
    });
}

export default {
  mixins: [organization],

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    Contract,
    Position,
    CardHeader,
    Certificates,
    Family,
    Education,
    Book,
    // Skill,
    // Language,
    Honor,
    Reward,
    Change,
    Performance,
    Personel,
    Train,
    Projects,
  },
  data() {
    return {
      organizationListNew: [],
      requireProfessionalTime: false,
      options: provinceAndCityData,
      selectedOptions: [],
      form: this.$form.createForm(this),

      icon1: require("@/assets/employee/icon1.png"),
      icon2: require("@/assets/employee/icon2.png"),
      icon3: require("@/assets/employee/icon3.png"),
      icon4: require("@/assets/employee/icon4.png"),
      icon5: require("@/assets/employee/icon5.png"),
      icon6: require("@/assets/employee/icon6.png"),
      icon7: require("@/assets/employee/icon7.png"),
      icon8: require("@/assets/employee/icon8.png"),
      icon9: require("@/assets/employee/icon9.png"),
      icon10: require("@/assets/employee/icon10.png"),
      icon11: require("@/assets/employee/icon11.png"),
      icon12: require("@/assets/employee/icon12.png"),
      icon13: require("@/assets/employee/icon13.png"),
      icon14: require("@/assets/employee/icon14.png"),
      icon15: require("@/assets/employee/icon15.png"),

      foldList: [],

      saveLoading: false,

      placeList: [],
      groupList: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    ...mapGetters("auth", ["isHr"]),
    lists() {
      return this.findDataDict("talentMap");
    },
    positionList() {
      return (
        this.lists.find((item) => item.value === "talentMap_post").children || []
      );
    },
    // 员工类别
    employeeType() {
      return (
        this.lists.find((item) => item.value === "employeeType").children || []
      );
    },
    // 婚姻状况
    marrigeStatus() {
      return (
        this.lists.find((item) => item.value === "marrigeStatus").children || []
      );
    },
    // 子女状况
    childStatus() {
      return (
        this.lists.find((item) => item.value === "childStatus").children || []
      );
    },
    // 残疾标识
    disabilitySign() {
      return (
        this.lists.find((item) => item.value === "disabilitySign").children ||
        []
      );
    },
    registerResidenceType() {
      return (
        this.lists.find((item) => item.value === "registerResidenceType").children ||
        []
      );
    },
    // 证件类型
    credentialType() {
      return (
        this.lists.find((item) => item.value === "credentialType").children ||
        []
      );
    },
    // 人员类型
    personType() {
      return (
        this.lists.find((item) => item.value === "personType").children || []
      );
    },
    // 关系类型
    relationshipType() {
      return (
        this.lists.find((item) => item.value === "relationshipType").children ||
        []
      );
    },
    // 学历
    degree() {
      return this.findDataDict("degree");
    },
    // 学位
    academic_degree() {
      return this.findDataDict("academic_degree");
    },
    // 学习方式
    education_channel() {
      return this.findDataDict("education_channel");
    },
    // 毕业方式
    graduationWay() {
      return (
        this.lists.find((item) => item.value === "graduationWay").children || []
      );
    },

    // 证书类型
    certificateType() {
      return this.findDataDict("talentMap.registeredProfessionalQualifications");
    },
    certificateTypeB() {
      return this.findDataDict("talentMap.professionalTitle");
    },

    // 职称等级
    professionalTitle() {
      return (
        this.lists.find((item) => item.value === "professionalTitle")
          .children || []
      );
    },
    // 荣誉标识
    honorLogo() {
      return (
        this.lists.find((item) => item.value === "honorLogo").children || []
      );
    },
    // 荣誉等级
    honorLevel() {
      return (
        this.lists.find((item) => item.value === "honorLevel").children || []
      );
    },
    externalHonorLevel() {
      return (
        this.lists.find((item) => item.value === "externalHonorLevel")
          .children || []
      );
    },
    // 标识
    logo() {
      return this.lists.find((item) => item.value === "logo").children || [];
    },

    rewardPenaltyType() {
      return (
        this.lists.find((item) => item.value === "rewardPenaltyType")
          .children || []
      );
    },
    // 异动类型
    changeType() {
      return (
        this.lists.find((item) => item.value === "changeType").children || []
      );
    },
    // 后备人才标识
    reserveTalentLogo() {
      return (
        this.lists.find((item) => item.value === "reserveTalentLogo")
          .children || []
      );
    },
    // 培训类型
    trainType() {
      return (
        this.lists.find((item) => item.value === "trainType").children || []
      );
    },

    sex() {
      return this.findDataDict("sex");
    },
    // 政治面貌
    politicalOutlookList() {
      return this.findDataDict("politicalOutlook");
    },

    // 血型
    bloodTypeList() {
      return this.findDataDict("bloodType");
    },
    partyList() {
      return this.findDataDict("partyMemberType");
    },
    employeeStatus() {
      return this.findDataDict("employeeStatus");
    },
    registeredProfessionalQualifications() {
      return this.findDataDict("talentMap.registeredProfessionalQualifications");
    }
  },

  watch: {
    detail() {
      if (this.detail.position && this.detail.position.belongDept) {
        this.onDeptChange(this.detail.position.belongDept);
      }
    },
  },

  mounted() {
    if(this.detail.professionalTitle == 'professionalTitle_none'){
        this.requireProfessionalTime = false
    }else{
        this.requireProfessionalTime = true
    }
    if (this.detail.position && this.detail.position.belongDept) {
      this.onDeptChange(this.detail.position.belongDept);
    }
    fetchOrganization().then((res)=>{
      this.organizationListNew = res
    })
  },

  methods: {
    qualificationsSelection(e){
      console.log(e)
      if(e == 'professionalTitle_none'){
          this.requireProfessionalTime = false
      }else{
          this.requireProfessionalTime = true
      }
    },
    updatePosition(data, index){
      this.detail.positionHisList = this.detail.positionHisList.map(v=>{
        return {
          ...v,
          top: false
        }
      })
      this.detail.positionHisList[index].top = true;
      console.log(this.detail)
      this.detail.position.belongDept = data.deptId
      fetchDetailTree({
        uuid: data.deptId,
      }).then((res) => {
        if (res) {
          this.placeList = Array.isArray(res.instituteVoList)
            ? res.instituteVoList
            : [];
          this.detail.instituteId = data.instituteId
          if (this.detail.instituteId) {
            const item = this.placeList.find((item) => item.id === this.detail.instituteId);
              if (item) {
                this.groupList = Array.isArray(item.groupVoList)
                  ? item.groupVoList
                  : [];
                  this.detail.groupId = data.groupId   
              }
          }
        }
      });
      this.detail.position.professionalize = data.professionalize
      this.detail.position.position = data.position
      this.detail.position.postSeries = data.postSeries
      this.detail.position.rank = data.rank
      this.detail.position.grade = data.grade
      this.detail.position.postStartTime = data.postStartTime
      this.detail.position.postEndTime = data.postEndTime
    },
    onFold(value) {
      const index = this.foldList.indexOf(value);
      if (index > -1) {
        this.foldList.splice(index, 1);
      } else {
        this.foldList.push(value);
      }
    },
    allClose() {
      this.foldList.push(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15);
    },
    getDateLength(key) {
      const str = this.form.getFieldValue(key) || this.detail[key];
      if(!str){
        return
      }
      
      if (str && str.format) {
        const month = moment().diff(str, 'month')
        if(month <= 6){
          return 0.5
        }else if(month > 6 && month <=12){
          return 1
        }else{
          const r = month%12
          if(r <= 6){
            return  Math.floor(month/12) + 0.5
          }else{
            return  Math.ceil(month/12)
          }
          
        }
      } else {
        return "--";
      }
    },


    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          if(!this.detail.educationList || this.detail.educationList.length<1){
            this.$message.error("请填写教育经历");
            return
          }
          this.saveLoading = true;
          try {
            edit({
              ...this.detail,
              ...values,
              registeredProfessionalQualifications: values.registeredProfessionalQualifications?.join(","),
              birthday: values.birthday ? values.birthday.format("YYYY-MM-DD") : undefined, // 出生日期
              workStartTime: values.workStartTime ? values.workStartTime.format("YYYY-MM-DD") : undefined, // 参加工作日期
              entryDate: values.entryDate ? values.entryDate.format("YYYY-MM-DD") : undefined, // 入职日期
              politicalDate: values.politicalDate
                ? values.politicalDate.format("YYYY-MM-DD")
                : undefined,

              contractStartDate: values.contractStartDate
                ? values.contractStartDate.format("YYYY-MM-DD")
                : undefined,
              contractEndDate: values.contractEndDate
                ? values.contractEndDate.format("YYYY-MM-DD")
                : undefined,

              // 岗位信息
              position: {
                professionalize: values.professionalize,
                belongDept: values.belongDept,
                directSupervisor: values.directSupervisor,
                postSeries: values.postSeries,
                position: values.position,
                grade: values.grade,
                rank: values.rank,
                rankStartTime: values.rankStartTime
                  ? values.rankStartTime.format("YYYY-MM-DD")
                  : undefined,
                postStartTime: values.postStartTime
                  ? moment(values.postStartTime).format("YYYY-MM-DD")
                  : undefined,
                postEndTime: values.postEndTime
                  ? moment(values.postEndTime).format("YYYY-MM-DD")
                  : undefined,
              },

              belongDept: undefined,
              directSupervisor: undefined,
              postSeries: undefined,
              grade: undefined,
              rank: undefined,
              rankStartTime: undefined,
              postStartTime: undefined,
              postEndTime: undefined,

              credentialList: Array.isArray(this.detail.credentialList)
                ? this.detail.credentialList.map((item) => ({
                  ...item,
                  expireDate: item.expireDate
                    ? item.expireDate.format("YYYY-MM-DD")
                    : undefined,
                }))
                : [],

              certList: Array.isArray(this.detail.certList)
                ? this.detail.certList.map((item) => {
                  return {
                    ...item,
                    issueDate: item.issueDate
                      ? item.issueDate.format("YYYY-MM-DD")
                      : undefined,
                    expireDate: item.expireDate
                      ? item.expireDate.format("YYYY-MM-DD")
                      : undefined,
                  };
                })
                : [],

              skillList: Array.isArray(this.detail.skillList)
                ? this.detail.skillList.map((item) => {
                  return {
                    ...item,
                    issueDate: item.issueDate
                      ? item.issueDate.format("YYYY-MM-DD")
                      : undefined,
                  };
                })
                : [],

              honorList: Array.isArray(this.detail.honorList)
                ? this.detail.honorList.map((item) => {
                  return {
                    ...item,
                    issueDate: item.issueDate
                      ? item.issueDate.format("YYYY-MM-DD")
                      : undefined,
                  };
                })
                : [],

              rewardPenaltyList: Array.isArray(this.detail.rewardPenaltyList)
                ? this.detail.rewardPenaltyList.map((item) => {
                  return {
                    ...item,
                    time: item.time
                      ? item.time.format("YYYY-MM-DD")
                      : undefined,
                  };
                })
                : [],

              // 实习
              probation: {
                term: values.term,
                result: values.result,
                startTime: values.startTime
                  ? values.startTime.format("YYYY-MM-DD")
                  : null,
                qualifiedTime: values.qualifiedTime
                  ? values.qualifiedTime.format("YYYY-MM-DD")
                  : null,
                endTimeExpected: values.endTimeExpected
                  ? values.endTimeExpected.format("YYYY-MM-DD")
                  : null,
                endTimeActual: values.endTimeActual
                  ? values.endTimeActual.format("YYYY-MM-DD")
                  : null,
              },
              term: undefined,
              result: undefined,
              startTime: undefined,
              qualifiedTime: undefined,
              endTimeExpected: undefined,
              endTimeActual: undefined,

              // 异动信息
              changeList: this.detail.changeList
                ? this.detail.changeList.map((item) => {
                  return {
                    ...item,
                    effectiveTime: item.effectiveTime
                      ? item.effectiveTime.format("YYYY-MM-DD")
                      : null,
                    changeTime: item.changeTime
                      ? item.changeTime.format("YYYY-MM-DD")
                      : null,
                  };
                })
                : [],

              // 人才识别
              talentIdentify: {
                abilityCommunicate: values.abilityCommunicate,
                abilityLearn: values.abilityLearn,
                abilityManage: values.abilityManage,
                abilitySale: values.abilitySale,
                abilitySkill: values.abilitySkill,
                comprehensive: values.comprehensive,
                culture: values.culture,
                evaluation3rd: values.evaluation3rd,
                internship: values.internship,
                reserveTalentFlag: values.reserveTalentFlag,
                result360: values.result360,
                score360: values.score360,
                scoreDevelop: values.scoreDevelop,
              },
              inventoryList: this.detail.inventoryList
                ? this.detail.inventoryList.map((item) => ({
                  ...item,
                  inventoryDate: item.inventoryDate
                    ? item.inventoryDate.format("YYYY-MM-DD")
                    : undefined,
                }))
                : [],

              // 培训记录
              trainList: this.detail.trainList
                ? this.detail.trainList
                  .filter((item) => item.name)
                  .map((item) => ({
                    ...item,
                    time: item.time
                      ? item.time.format("YYYY-MM-DD")
                      : undefined,
                  }))
                : [],

              hisWorkList: this.detail.hisWorkList
                ? this.detail.hisWorkList.map((item) => ({
                  ...item,
                  timeFrom: item.timeFrom?.format("YYYY-MM-DD"),
                  timeTo: item.timeTo?.format("YYYY-MM-DD"),
                }))
                : [],

              hisProjectList: this.detail.hisProjectList
                ? this.detail.hisProjectList.map((item) => ({
                  ...item,
                  timeFrom: item.timeFrom?.format("YYYY-MM-DD"),
                  timeTo: item.timeTo?.format("YYYY-MM-DD"),
                }))
                : [],
            })
              .then(() => { })
              .finally(() => {
                this.saveLoading = false;
              });
          } catch (error) {
            this.saveLoading = false;
            console.log("error", error);
            alert(JSON.stringify(error));
          }
        } else {
          this.$message.error("请填写必填项");
        }
      });
    },

    handleDeptChange(value) {
      this.form.setFieldsValue({
        instituteId: null,
        groupId: null,
      });
      this.onDeptChange(value);
    },

    onDeptChange(value) {
      this.placeList = [];
      this.groupList = [];

      fetchDetailTree({
        uuid: value,
      }).then((res) => {
        if (res) {
          this.placeList = Array.isArray(res.instituteVoList)
            ? res.instituteVoList
            : [];

          if (this.detail.instituteId) {
            this.onPlaceChange(this.detail.instituteId);
          }
        }
      });
    },

    onPlaceChange(value) {
      const item = this.placeList.find((item) => item.id === value);
      if (item) {
        this.groupList = Array.isArray(item.groupVoList)
          ? item.groupVoList
          : [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.base-info {
  position: relative;

  .tool {
    position: absolute;
    top: -32px;
    right: 0;
  }
}

.center {
  margin-top: 80px;
  padding-bottom: 80px;
}
</style>