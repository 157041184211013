<template>
  <div style="padding-bottom: 12px">
    <div class="right">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.inventoryList) ? detail.inventoryList : []
      "
      :pagination="false"
    >
      <a-table-column title="人才盘点日期">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.inventoryDate"
          />
        </template>
      </a-table-column>
      <a-table-column title="人才盘点结果">
        <template slot-scope="text">
          <a-input
            v-model="text.inventoryResult"
            placeholder="请输入"
            size="small"
            style="width: 240px"
          />
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    add() {
      console.log("add");
      if (Array.isArray(this.detail.inventoryList)) {
        this.detail.inventoryList.push({});
      } else {
        this.detail.inventoryList = [{}];
      }
    },

    remove(index) {
      this.detail.inventoryList.splice(index, 1);
    },
  },
};
</script>