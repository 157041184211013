<template>
  <div style="padding-bottom: 12px">
    <div class="right">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.perfAppraiseList) ? detail.perfAppraiseList : []
      "
      :pagination="false"
    >
      <a-table-column title="考核年度">
        <template slot-scope="text">
          <a-input
            v-model="text.year"
            placeholder="请输入"
            size="small"
            style="width: 120px"
          />
        </template>
      </a-table-column>
      <a-table-column title="考核结果(分)">
        <template slot-scope="text">
          <a-input
            v-model="text.result"
            placeholder="请输入"
            size="small"
            style="width: 120px"
          />
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    add() {
      if (Array.isArray(this.detail.perfAppraiseList)) {
        this.detail.perfAppraiseList.push({});
      } else {
        this.detail.perfAppraiseList = [{}];
      }
    },
    remove(index) {
      this.detail.perfAppraiseList.splice(index, 1);
    },
  },
};
</script>