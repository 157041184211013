<template>
  <div>
    <Pane />
    <a-card class="container">
      <BaseInfo v-if="detail.id" :detail="detail" />
    </a-card>
  </div>
</template>

<script>
import BaseInfo from "./components/base-info.vue";
import { fetchDetail } from "@/api/human-resources/employee";
import moment from "moment";

export default {
  name: "editEmployee",

  components: {
    BaseInfo,
  },

  data() {
    return {
      id: null,
      detail: {},
    };
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      this.detail = {};
      fetchDetail({
        id,
      }).then((res) => {
        if (res) {
          let position = {};

          if (res.position) {
            position = {
              ...res.position,
              rankStartTime: res.position.rankStartTime
                ? moment(res.position.rankStartTime)
                : null,
              postStartTime: res.position.postStartTime
                ? moment(res.position.postStartTime)
                : null,
              postEndTime: res.position.postEndTime
                ? moment(res.position.postEndTime)
                : null,
            };
          }

          // 实习
          let probation = {};
          if (res.probation) {
            probation = {
              ...res.probation,
              startTime: res.probation.startTime
                ? moment(res.probation.startTime)
                : null,
              qualifiedTime: res.probation.qualifiedTime
                ? moment(res.probation.qualifiedTime)
                : null,
              endTimeExpected: res.probation.endTimeExpected
                ? moment(res.probation.endTimeExpected)
                : null,
              endTimeActual: res.probation.endTimeActual
                ? moment(res.probation.endTimeActual)
                : null,
            };
          }

          this.detail = {
            ...res,
            birthday: res.birthday ? moment(res.birthday) : null,
            workStartTime: res.workStartTime ? moment(res.workStartTime) : null,
            entryDate: res.entryDate ? moment(res.entryDate) : null,
            politicalDate: res.politicalDate ? moment(res.politicalDate) : null,

            position,

            contractStartDate: res.contractStartDate
              ? moment(res.contractStartDate)
              : null,
            contractEndDate: res.contractEndDate
              ? moment(res.contractEndDate)
              : null,

            // 证件信息
            credentialList: res.credentialList
              ? res.credentialList.map((item) => ({
                  ...item,
                  expireDate: item.expireDate ? moment(item.expireDate) : null,
                }))
              : [],

            // 证书信息
            certList: res.certList
              ? res.certList.map((item) => {
                  return {
                    ...item,
                    issueDate: item.issueDate ? moment(item.issueDate) : null,
                    expireDate: item.expireDate
                      ? moment(item.expireDate)
                      : null,
                  };
                })
              : [],

            // 技能
            skillList: res.skillList
              ? res.skillList.map((item) => {
                  return {
                    ...item,
                    issueDate: item.issueDate ? moment(item.issueDate) : null,
                  };
                })
              : [],

            honorList: res.honorList
              ? res.honorList.map((item) => {
                  return {
                    ...item,
                    issueDate: item.issueDate
                      ? moment(item.issueDate)
                      : undefined,
                  };
                })
              : [],

            probation,

            // 异动信息
            changeList: res.changeList
              ? res.changeList.map((item) => {
                  return {
                    ...item,
                    effectiveTime: item.effectiveTime
                      ? moment(item.effectiveTime)
                      : null,
                    changeTime: item.changeTime
                      ? moment(item.changeTime)
                      : null,
                  };
                })
              : [],

            rewardPenaltyList: res.rewardPenaltyList
              ? res.rewardPenaltyList.map((item) => {
                  return {
                    ...item,
                    time: item.time ? moment(item.time) : undefined,
                  };
                })
              : [],

            inventoryList: res.inventoryList
              ? res.inventoryList.map((item) => ({
                  ...item,
                  inventoryDate: item.inventoryDate
                    ? moment(item.inventoryDate)
                    : undefined,
                }))
              : [],

            // 培训记录
            trainList: res.trainList
              ? res.trainList.map((item) => ({
                  ...item,
                  time: item.time ? moment(item.time) : undefined,
                }))
              : [],

            hisWorkList: res.hisWorkList?.map((item) => {
              return {
                ...item,
                timeFrom: item.timeFrom ? moment(item.timeFrom) : null,
                timeTo: item.timeTo ? moment(item.timeTo) : null,
              };
            }),
            hisProjectList: res.hisProjectList?.map((item) => {
              return {
                ...item,
                timeFrom: item.timeFrom ? moment(item.timeFrom) : null,
                timeTo: item.timeTo ? moment(item.timeTo) : null,
              };
            }),
          };
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 42px 16px 16px;
}
</style>
