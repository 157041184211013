<template>
  <div style="padding-bottom: 12px">
    <div class="right">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table bordered :data-source="Array.isArray(detail.contractList) ? detail.contractList : []
      " :pagination="false">
      <a-table-column title="合同文件" align="center">
          <template slot-scope="text">
              {{ text.file?text.file.name:'--' }}
          </template>
        </a-table-column>
      <a-table-column title="合同开始日期" data-index="startDate" />
      <a-table-column title="合同结束日期" data-index="endDate" />
      <a-table-column title="合同类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="talentMap.employeeContractType" :dictValue="text.type" />
          </template>
        </a-table-column>
      <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="talentMap.employeeContractStatus"
              :dictValue="text.status"
              iconType="badge"
            />
          </template>
        </a-table-column>
      <a-table-column title="操作" align="right" width="200px">
        <template slot-scope="text, row, index">
          <a-space>
            <a v-if="text.file" :href="text.file.completePath">合同下载</a>
            <a href="#" @click.prevent="edit(text, index)">编辑</a>
            <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <a-modal :title="type === 'add' ? '添加合同信息' : '修改合同信息'" :visible="visible" :footer="null" @cancel="cancel"
      width="800px">
      <a-form :colon="false" :form="form" @submit="handleSubmit">
        <a-row :gutter="8">
          <a-col :span="8">
            <a-form-item label="合同开始日期">
              <a-date-picker placeholder="" style="width: 100%" v-decorator="[
                'startDate',
                {
                  initialValue: editingText.startDate
                    ? moment(editingText.startDate)
                    : null,

                  rules: [{ required: true, message: '请选择！' }],
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="合同结束日期">
              <a-date-picker placeholder="" style="width: 100%" v-decorator="[
                'endDate',
                {
                  initialValue: editingText.endDate
                    ? moment(editingText.endDate)
                    : null,
                },
              ]" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
              <a-form-item label="合同类型">
                <a-select
                  v-decorator="[
                    'type',
                    {
                      initialValue: editingText.type,
                      rules: [
                        {
                          required: true,
                          message: '请选择！',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="item in typeList"
                    :key="item.value"
                    :value="item.value"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
          </a-col>
          <a-col :span="8">
              <a-form-item label="合同状态">
                <a-radio-group v-decorator="[
                  'status',
                  { 
                    initialValue: editingText.status,
                    rules: [{ required: true, message: '请选择！' }] },
                ]">
                  <a-radio v-for="item in signStatusList" :key="item.id"
                    :value="item.value">{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="合同文件">
              <div style="margin-top: -4px; margin-left: -8px">
                <Uploader :list="fileList" @change="(arr) => (fileList = arr)" />
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit" :loading="saveLoading">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import Uploader from "@/components/uploader";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  components: {
    Uploader,
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    graduationWay: {
      type: Array,
      default: () => [],
    },
    education_channel: {
      type: Array,
      default: () => [],
    },
    degree: {
      type: Array,
      default: () => [],
    },
    academic_degree: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    signStatusList() {
      return this.findDataDict("talentMap.employeeContractStatus");
    },
    typeList() {
      return this.findDataDict("talentMap.employeeContractType");
    },
  },
  data() {
    return {
      fileList: [],
      form: this.$form.createForm(this),
      visible: false,
      type: "",
      editingIndex: -1,
      editingText: {},
      saveLoading: false,

      contractAttachment: "",
      academicDegreeAttachment: "",
    };
  },
  methods: {
    moment,
    add() {
      this.visible = true;
      this.type = "add";
    },
    edit(text, index) {
      console.log(text)
      this.editingText = text;
      this.editingIndex = index;
      this.visible = true;
      this.type = "edit";
      this.fileList = text.file ? [{
        id: text.file.id,
        name: text.file.name
      }] : [];
      this.contractAttachment = text.contractAttachment;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            ...values,
            startDate: values.startDate.format("YYYY-MM-DD"),
            endDate: values.endDate ? values.endDate.format("YYYY-MM-DD") : null,
            fileId: this.fileList.map((item) => item.id).join(","),
            file: this.fileList[0]
          };
          console.log(params)
          if (this.type === "add") {
            if (Array.isArray(this.detail.contractList)) {
              this.detail.contractList.push(params);
            } else {
              this.detail.contractList = [params];
            }
          } else {
            this.detail.contractList.splice(this.editingIndex, 1, {
              ...this.editingText,
              ...params,
            });
          }

          this.cancel();
        }
      });
    },

    cancel() {
      this.visible = false;
      this.editingIndex = -1;
      this.editingText = {};
      this.type = "";
      this.form.resetFields();
      this.contractAttachment = "";
      this.fileList = []
    },

    remove(index) {
      this.detail.contractList.splice(index, 1);
    },

    contractAttachmentChange(url) {
      this.contractAttachment = url;
    },
  },
};
</script>