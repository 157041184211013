<template>
  <div style="padding-bottom: 12px">
    <div class="right">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table
      bordered
      :data-source="Array.isArray(detail.honorList) ? detail.honorList : []"
      :pagination="false"
    >
      <a-table-column title="荣誉类型">
        <template slot-scope="text">
          <a-select
            v-model="text.type"
            @change="text.rank = null"
            placeholder="请选择"
            size="small"
            style="width: 120px"
          >
            <a-select-option
              v-for="item in honorLogo"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>
      <a-table-column title="荣誉等级">
        <template slot-scope="text">
          <a-select
            v-model="text.rank"
            placeholder="请选择"
            size="small"
            style="width: 120px"
          >
            <a-select-option
              v-for="item in text.type
                ? text.type === 'internal'
                  ? honorLevel
                  : externalHonorLevel
                : []"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>
      <a-table-column title="所获荣誉">
        <template slot-scope="text">
          <a-input
            style="width: 120px"
            size="small"
            placeholder="请输入"
            v-model="text.name"
          />
        </template>
      </a-table-column>

      <a-table-column title="授予日期">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.issueDate"
          />
        </template>
      </a-table-column>
      <a-table-column title="授予机构">
        <template slot-scope="text">
          <a-input
            style="width: 120px"
            size="small"
            placeholder="请输入"
            v-model="text.issueUnit"
          />
        </template>
      </a-table-column>

      <a-table-column title="附件" align="center">
        <template slot-scope="text">
          <Uploader
            size="small"
            :url="text.attachment"
            @remove="text.attachment = ''"
            @change="(url) => (text.attachment = url)"
          />
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
import Uploader from "./uploader.vue";
export default {
  components: {
    Uploader,
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },

    honorLogo: {
      type: Array,
      default: () => [],
    },
    honorLevel: {
      type: Array,
      default: () => [],
    },
    externalHonorLevel: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    add() {
      if (Array.isArray(this.detail.honorList)) {
        this.detail.honorList.push({});
      } else {
        this.detail.honorList = [{}];
      }
    },

    remove(index) {
      this.detail.honorList.splice(index, 1);
    },
  },
};
</script>