<template>
  <div style="padding-bottom: 12px">
    <div class="right">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table bordered :data-source="Array.isArray(detail.educationList) ? detail.educationList : []
      " :pagination="false">
      <a-table-column title="毕业院校" data-index="school" />
      <a-table-column title="专业" data-index="major" />
      <a-table-column title="学习方式" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="education_channel" :dictValue="text.channelStudy" />
        </template>
      </a-table-column>
      <a-table-column title="就读日期" data-index="timeFrom" align="center" />
      <a-table-column title="毕业日期" data-index="timeTo" align="center" />
      <a-table-column title="毕业方式" align="center">
        <template slot-scope="text">
          <span v-if="graduationWay.length">
            {{
              graduationWay.find((item) => item.value === text.channelGraduate)
                ?.name
            }}
          </span>
        </template>
      </a-table-column>

      <a-table-column title="学历" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="degree" :dictValue="text.education" />
        </template>
      </a-table-column>

      <a-table-column title="学历证书编号">
        <template slot-scope="text">
          {{ text.educationNum }}
        </template>
      </a-table-column>

      <a-table-column title="学历附件" align="center">
        <template slot-scope="text">
          <a target="_blank" v-if="text.educationAttachment" :href="text.educationAttachment">查看</a>
        </template>
      </a-table-column>

      <a-table-column title="学位" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="academic_degree" :dictValue="text.academicDegree" />
        </template>
      </a-table-column>

      <a-table-column title="学位证书编号">
        <template slot-scope="text">
          {{ text.academicDegreeNum }}
        </template>
      </a-table-column>

      <a-table-column title="学位附件" align="center">
        <template slot-scope="text">
          <a target="_blank" v-if="text.academicDegreeAttachment" :href="text.academicDegreeAttachment">查看</a>
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a-space>
            <a href="#" @click.prevent="edit(text, index)">编辑</a>
            <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <a-modal :title="type === 'add' ? '添加教育经历' : '修改教育经历'" :visible="visible" :footer="null" @cancel="cancel"
      width="800px">
      <a-form :colon="false" :form="form" @submit="handleSubmit">
        <a-row :gutter="8">
          <a-col :span="8">
            <a-form-item label="毕业院校">
              <a-input v-decorator="[
                'school',
                {
                  initialValue: editingText.school,
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="专业">
              <a-input v-decorator="[
                'major',
                {
                  initialValue: editingText.major,

                  rules: [{ required: true, message: '请输入！' }],
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="学习方式">
              <a-select style="width: 100%" v-decorator="[
                'channelStudy',
                {
                  initialValue: editingText.channelStudy,

                  rules: [{ required: true, message: '请选择！' }],
                },
              ]">
                <a-select-option v-for="item in education_channel" :key="item.value" :value="item.value">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="就读日期">
              <a-date-picker placeholder="" style="width: 100%" v-decorator="[
                'timeFrom',
                {
                  initialValue: editingText.timeFrom
                    ? moment(editingText.timeFrom)
                    : null,

                  rules: [{ required: true, message: '请选择！' }],
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="毕业日期">
              <a-date-picker placeholder="" style="width: 100%" v-decorator="[
                'timeTo',
                {
                  initialValue: editingText.timeTo
                    ? moment(editingText.timeTo)
                    : null,

                  rules: [{ required: true, message: '请选择！' }],
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="毕业方式">
              <a-select style="width: 100%" v-decorator="[
                'channelGraduate',
                {
                  initialValue: editingText.channelGraduate,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]">
                <a-select-option v-for="item in graduationWay" :key="item.value" :value="item.value">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="学历">
              <a-select style="width: 100%" v-decorator="[
                'education',
                {
                  initialValue: editingText.education,

                  rules: [{ required: true, message: '请选择！' }],
                },
              ]">
                <a-select-option v-for="item in degree" :key="item.value" :value="item.value">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="学历证书编号">
              <a-input v-decorator="[
                'educationNum',
                {
                  initialValue: editingText.educationNum,
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="学历附件">
              <div style="margin-top: -4px; margin-left: -8px">
                <Uploader :url="educationAttachment" @change="educationAttachmentChange"
                  @remove="educationAttachment = ''" />
              </div>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="学位">
              <a-select style="width: 100%" v-decorator="[
                'academicDegree',
                {
                  initialValue: editingText.academicDegree,

                  rules: [{ required: true, message: '请选择！' }],
                },
              ]">
                <a-select-option v-for="item in academic_degree" :key="item.value" :value="item.value">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="学位证书编号">
              <a-input v-decorator="[
                'academicDegreeNum',
                {
                  initialValue: editingText.academicDegreeNum,
                },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="学位附件">
              <div style="margin-top: -4px; margin-left: -8px">
                <Uploader :url="academicDegreeAttachment" @change="academicDegreeAttachmentChange"
                  @remove="academicDegreeAttachment = ''" />
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit" :loading="saveLoading">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import Uploader from "./uploader.vue";
import moment from "moment";
export default {
  components: {
    Uploader,
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    graduationWay: {
      type: Array,
      default: () => [],
    },
    education_channel: {
      type: Array,
      default: () => [],
    },
    degree: {
      type: Array,
      default: () => [],
    },
    academic_degree: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      type: "",
      editingIndex: -1,
      editingText: {},
      saveLoading: false,

      educationAttachment: "",
      academicDegreeAttachment: "",
    };
  },
  methods: {
    moment,
    add() {
      this.visible = true;
      this.type = "add";
    },
    edit(text, index) {
      this.editingText = text;
      this.editingIndex = index;
      this.visible = true;
      this.type = "edit";
      this.educationAttachment = text.educationAttachment;
      this.academicDegreeAttachment = text.academicDegreeAttachment;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            ...values,
            timeFrom: values.timeFrom.format("YYYY-MM-DD"),
            timeTo: values.timeTo.format("YYYY-MM-DD"),
            educationAttachment: this.educationAttachment,
            academicDegreeAttachment: this.academicDegreeAttachment,
          };

          if (this.type === "add") {
            if (Array.isArray(this.detail.educationList)) {
              this.detail.educationList.push(params);
            } else {
              this.detail.educationList = [params];
            }
          } else {
            this.detail.educationList.splice(this.editingIndex, 1, {
              ...this.editingText,
              ...params,
            });
          }

          this.cancel();
        }
      });
    },

    cancel() {
      this.visible = false;
      this.editingIndex = -1;
      this.editingText = {};
      this.type = "";
      this.form.resetFields();
      this.educationAttachment = "";
      this.academicDegreeAttachment = "";
    },

    remove(index) {
      this.detail.educationList.splice(index, 1);
    },

    educationAttachmentChange(url) {
      this.educationAttachment = url;
    },

    academicDegreeAttachmentChange(url) {
      this.academicDegreeAttachment = url;
    },
  },
};
</script>