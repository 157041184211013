<template>
  <div style="padding-bottom: 12px">
    <div class="right">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.credentialList) ? detail.credentialList : []
      "
      :pagination="false"
    >
      <a-table-column title="证件类型">
        <template slot-scope="text">
          <a-select
            v-model="text.type"
            placeholder="请选择"
            size="small"
            style="width: 120px"
          >
            <a-select-option
              v-for="item in credentialType"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>
      <a-table-column title="证件编号">
        <template slot-scope="text">
          <a-input
            style="width: 120px"
            size="small"
            placeholder="请输入"
            v-model="text.sn"
          />
        </template>
      </a-table-column>
      <a-table-column title="证件号码">
        <template slot-scope="text">
          <a-input
            style="width: 120px"
            size="small"
            placeholder="请输入"
            v-model="text.number"
          />
        </template>
      </a-table-column>
      <a-table-column title="到期日期">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.expireDate"
          />
        </template>
      </a-table-column>
      <a-table-column title="发证机关">
        <template slot-scope="text">
          <a-input
            style="width: 240px"
            size="small"
            placeholder="请输入"
            v-model="text.issueUnit"
          />
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    credentialType: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    add() {
      if (Array.isArray(this.detail.credentialList)) {
        this.detail.credentialList.push({});
      } else {
        this.detail.credentialList = [{}];
      }
    },
    remove(index) {
      this.detail.credentialList.splice(index, 1);
    },
  },
};
</script>