<template>
  <div style="padding-bottom: 12px">
    <div class="right">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.rewardPenaltyList) ? detail.rewardPenaltyList : []
      "
      :pagination="false"
    >
      <a-table-column title="惩罚类型">
        <template slot-scope="text">
          <a-input
            v-model="text.penalty"
            placeholder="请输入"
            size="small"
            style="width: 120px"
          />
        </template>
      </a-table-column>
      <a-table-column title="惩罚措施">
        <template slot-scope="text">
          <a-input
            v-model="text.content"
            placeholder="请输入"
            size="small"
            style="width: 120px"
          />
        </template>
      </a-table-column>
      <a-table-column title="惩罚日期">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.time"
          />
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    rewardPenaltyType: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    add() {
      if (Array.isArray(this.detail.rewardPenaltyList)) {
        this.detail.rewardPenaltyList.push({});
      } else {
        this.detail.rewardPenaltyList = [{}];
      }
    },

    remove(index) {
      this.detail.rewardPenaltyList.splice(index, 1);
    },
  },
};
</script>