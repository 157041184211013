<template>
  <div style="padding-bottom: 12px">
    <div class="right">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table
      bordered
      :data-source="Array.isArray(detail.certList) ? detail.certList : []"
      :pagination="false"
    >
    <a-table-column title="证书类型" align="center">
        <template slot-scope="text, row, index">
          <a-select
            @change="onTypeChange(text, index)"
            v-model="text.type"
            placeholder="请选择"
            size="small"
            style="width: 200px"
          >
            <a-select-option
              v-for="item in certificateTypeA"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>
      <a-table-column title="证书名称" align="center">
        <template slot-scope="text">
          <a-select
            v-if="text.type == 'registeredCert'"
            v-model="text.name"
            placeholder="请选择"
            size="small"
            style="width: 200px"
          >
            <a-select-option
              v-for="item in certificateType"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
          <a-select
            v-if="text.type == 'professionalCert'"
            v-model="text.name"
            placeholder="请选择"
            size="small"
            style="width: 200px"
          >
            <a-select-option
              v-for="item in certificateTypeB"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
          <a-input
            v-if="text.type == 'other'"
            v-model="text.name"
            placeholder="请输入"
            size="small"
            style="width: 120px"
          />
        </template>
      </a-table-column>

      <a-table-column title="证书编号">
        <template slot-scope="text">
          <a-input
            v-model="text.number"
            placeholder="请输入"
            size="small"
            style="width: 120px"
          />
        </template>
      </a-table-column>
      <a-table-column title="发证日期" align="center">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.issueDate"
          />
        </template>
      </a-table-column>
      <a-table-column title="过期日期" align="center">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.expireDate"
          />
        </template>
      </a-table-column>
      <a-table-column title="附件" align="center">
        <template slot-scope="text">
          <Uploader
            size="small"
            :url="text.attachment"
            @remove="text.attachment = ''"
            @change="(url) => (text.attachment = url)"
          />
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
import Uploader from "./uploader.vue";
export default {
  components: {
    Uploader,
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    certificateType: {
      type: Array,
      default: () => [],
    },
    certificateTypeB: {
      type: Array,
      default: () => [],
    },
  },
  data(){
    return{
      certificateTypeA:[
        {
          name: '注册证书',
          value: 'registeredCert'
        },
        {
          name: '职称证书',
          value: 'professionalCert'
        },
        {
          name: '其他',
          value: 'other'
        }
      ]
    }
  },
  methods: {
    onTypeChange(text, index){
      this.detail.certList[index].name = ''
    },
    add() {
      if (Array.isArray(this.detail.certList)) {
        this.detail.certList.push({
          type: '',
          name: '',
        });
      } else {
        this.detail.certList = [{}];
      }
    },

    remove(index) {
      this.detail.certList.splice(index, 1);
    },
  },
};
</script>